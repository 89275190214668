export enum USERS_ROLES {
  Teacher,
  Student,
  SuperAdmin,
}

export type Role = {
  id: string | null;
  name: USERS_ROLES;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
};

export type User = {
  id: string | null;
  isActive: boolean;
  isBlocked: boolean;
  email: string | null;
  RoleId: string | null;
  Role: Role;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
};

export type Profile = {
  id: string | null;
  phone: string | null;
  UserId: string | null;
  fullName: string | null;
  School: School;
  picturePath: string | null;
  User: User;
  createdAt: Date | null;
  updatedAt: Date | null;
  deletedAt: Date | null;
};

export interface School {
  id: string;
  name: string;
}

export type Grade = {
  id: string | null;
  name: string | null;
};

export type SomeUserLoginDetails = {
  token: string;
  uid: string;
  profile: Profile;
  grade: Grade;
};

export const DEFAULT_USER = {
  token: "",
  uid: "",
  profile: {
    id: "",
    fullName: "",
    phone: "",
    picturePath: "",
    schoolId: "",
    UserId: "",
    createdAt: null,
    updatedAt: null,
    deletedAt: null,
    School: {
      id: "",
      name: "",
    },
    User: {
      id: "",
      email: "",
      isActive: true,
      isBlocked: false,
      RoleId: "",
      createdAt: null,
      updatedAt: null,
      deletedAt: null,
      Role: {
        id: "",
        name: USERS_ROLES.Student,
        createdAt: null,
        updatedAt: null,
        deletedAt: null,
      },
    },
  },
  grade: {
    id: "",
    name: "",
  },
};

export interface AuthContextType {
  isLoggedIn: boolean;
  user: SomeUserLoginDetails;
  setUserLoggedOut: () => void;
  setLoggedUser: (data: SomeUserLoginDetails) => void;
}
