import i18next from "i18next";
import _superagent from "superagent";
import superagentPromise from "superagent-promise";

import { errorHandler } from "./responseHandler";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_SERVER_URL || "";

const responseBody = (res) => res.body;

// const encode = encodeURIComponent;

let token = null;
const tokenPlugin = (req) => {
  if (token) {
    req.set("authorization", `Bearer ${token}`);
  }
};

const requests = {
  del: (url) =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  get: (url) =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
  post: (url, body?) =>
    superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody)
      .catch(errorHandler),
};

const Auth = {
  login: (email, password) =>
    requests.post("/auth/sign-in?lang=en", { email, password }),
  signup: (username, email, password) =>
    requests.post("/auth/sign-up?lang=en", {
      user: { username, email, password },
    }),
  sendEmail: (email) => requests.post("/auth/sendEmail", { email }),
  setPassword: (token, newPassword, confirmNewPassword) =>
    requests.post(`/password?lang=en&token=${token}`, {
      newPassword,
      confirmNewPassword,
    }),
  getUsers: () => requests.get("/user?lang=en"),
  profile: () => requests.get("/profile/me"),
  profileCourses: () => requests.get("/profile/courses"),
};

const Dashboard = {
  statCards: (profileId: string) =>
    requests.get(`/studentDashboard/${profileId}`),
  getUpcomingEvents: () => requests.get("/assessment/upcomingEvents?lang=en"),
};

const Course = {
  courseStatCards: (profileId: string, courseId: string) =>
    requests.get(`/studentDashboard/${profileId}/${courseId}`),
  courseStudentStengthAndWeakness: (profileId: string, courseId: string) =>
    requests.get(`/sawS/${profileId}/${courseId}`),
  getLecturesByCourseId: (courseId: string) =>
    requests.get(`/lecture/${courseId}`),
  getCategoriesByCourseId: (courseId: string) =>
    requests.get(`/course/${courseId}/categories?lang=en`),
};

const AIAssisstant = {
  getWeaknessRelatedDocs: (weaknessBody) =>
    requests.post(`/genai/related_documents?lang=${i18next?.language}`, {
      ...weaknessBody,
    }),
  sendChat: (chatBody) =>
    requests.post(`/genai/search?lang=${i18next?.language}`, {
      ...chatBody,
    }),
};

const Assessments = {
  getStudentExams: () => requests.get("/assessment/submission/exams?lang=en"),
  getStudentAssessments: () =>
    requests.get("/assessment/submission/assignment?lang=en"),
  getAssessmentToFill: (submissionId) =>
    requests.get(`/assessment/submission/${submissionId}?lang=en`),
  getStudentAssessmentsByCourseId: (courseId) =>
    requests.get(
      `/assessment/submission/course/assignment/${courseId}?lang=en`
    ),
  getStudentExamByCourseId: (courseId) =>
    requests.get(`/assessment/submission/course/exam/${courseId}?lang=en`),
  getStudentAssessmentById: (assessmentId) =>
    requests.get(`/assessment/${assessmentId}?lang=en`),
  getAssessmentDetailById: (assessmentId) =>
    requests.get(`/assessment/${assessmentId}?lang=en`),
  updateAssessmenSubmission: (assessmentBody) =>
    requests.post("/assessment/updateSubmission?lang=en", {
      ...assessmentBody,
    }),
  submitAssessmentSubmission: (assessmentBody) =>
    requests.post("/assessment/submitAssessment?lang=en", {
      ...assessmentBody,
    }),
  generatePracticeAssessment: (assessmentBody) =>
    requests.post(
      `/assessment/generatePracticeAssessment?lang=${i18next?.language}`,
      {
        ...assessmentBody,
      }
    ),
  submitPracticeAssessmentSubmission: (assessmentBody) =>
    requests.post("/assessment/submitPracticeAssessment?lang=en", {
      ...assessmentBody,
    }),
};

const Recommendation = {
  getFilteredRecommendedLectureByStudentId: (studentId: string, filterBody) =>
    requests.post(`/lecture/student/${studentId}`, filterBody),
  getRecommendedLectureByCategoryId: (categoryId: string) =>
    requests.get(`/lecture/category/${categoryId}`),
  viewLecture: (lectureId) => requests.get(`/lecture/views/${lectureId}`),
};

const agent = {
  Auth,
  Course,
  Dashboard,
  Assessments,
  AIAssisstant,
  Recommendation,
  setToken: (_token) => (token = _token),
};

export default agent;
